import React from "react"
import { Router } from "@reach/router"
import Edit from "../../components/Edit"

const App = () => {
  return (
      <Router basepath="/app">
        <Edit path="/edit" />
      </Router>
  )
}
export default App