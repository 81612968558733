import * as React from 'react';
import PageLayout from '../components/PageLayout.js';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';

class EditPage extends React.Component {
   state = { editMode: [] }
   static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  };

  constructor(props) {
    super(props);

    const { cookies } = props;
    this.state = {
      editMode: cookies.get('editMode') === 'true'
    };
  }

  componentDidMount() {
    
  }

  handleChange = (event) => {
    const { cookies } = this.props;

    if(event.target.checked){
      cookies.set('editMode', 'true', { path: '/' });
    }
    else {
      cookies.set('editMode', 'false', { path: '/' });
    }
    this.setState({ editMode: event.target.checked });
  };

  render() {
    const { editMode } = this.state;
    return (
      <PageLayout>
          
          <Box sx={{ pt: 2 }} >
            <Typography variant="h2" component="h2" >Headstart Administration</Typography>

            <FormGroup>
            <FormControlLabel control={<Switch checked={editMode} onChange={this.handleChange} />} label="Edit Mode" />
            <span>{editMode}</span>
             </FormGroup>
          </Box>
        </PageLayout>
    )
  }
}

export default withCookies(EditPage)
